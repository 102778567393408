import { CSSProperties, useEffect, useRef, useState } from 'react'

interface DropdownProps {
    parentRef: React.RefObject<HTMLDivElement | null>
    /**
     * Dropdown parent focus state
     */
    isFocus?: boolean
    /**
     * Min width of dropdown
     */
    dropdownMinWidth?: number
    /**
     * Prepend element to dropdown
     */
    prepend?: JSX.Element
    /**
     * Append element to dropdown
     */
    append?: JSX.Element
    /**
     * List of dropdown items
     */
    children: JSX.Element | JSX.Element[]
    /**
     * Maximum height
     */
    maxHeight?: number
    /**
     * Position
     */
    position?: 'left' | 'right'
    /**
     * Anchor
     */
    anchor?: 'top' | 'bottom'
    /**
     * Auto close when select
     */
    autoClose?: boolean
}

export const Dropdown = ({
    parentRef,
    isFocus = false,
    dropdownMinWidth,
    prepend,
    append,
    children,
    maxHeight = 300,
    position = 'left',
    anchor = 'bottom',
    autoClose = true
}: DropdownProps) => {
    const dropdownRef = useRef<HTMLDivElement | null>(null)
    const [style, setStyle] = useState<CSSProperties>({})

    const updatePosition = () => {
        if (!parentRef?.current || !dropdownRef?.current) {
            return
        }

        const parentRect = parentRef.current.getBoundingClientRect()
        const dropdownRect = dropdownRef.current.getBoundingClientRect()

        const newStyle: CSSProperties = {
            position: 'fixed', // Use fixed positioning
            width: parentRect.width,
            maxHeight,
            minWidth: dropdownMinWidth || 0
        }

        if (anchor === 'bottom') {
            newStyle.top = parentRect.bottom + window.scrollY // Adjust for scroll position
        } else {
            newStyle.top = parentRect.top - dropdownRect.height + window.scrollY // Directly above the parent
        }

        if (position === 'right') {
            newStyle.left = parentRect.right - parentRect.width + window.scrollX // Align to the right of the parent
        } else {
            newStyle.left = parentRect.left + window.scrollX // Align to the left of the parent
        }

        setStyle(newStyle)
    }

    useEffect(() => {
        updatePosition() // Update position on scroll
    }, [parentRef, dropdownRef, anchor, position, maxHeight, dropdownMinWidth, isFocus])

    return (
        <div
            ref={dropdownRef}
            className={`${
                anchor === 'bottom' ? 'shadow-lg' : 'border'
            } rounded-lg overflow-hidden bg-white transition-opacity ease-linear ${
                isFocus ? 'opacity-100 z-10' : 'opacity-0 -z-50'
            } flex flex-col`}
            style={style}
            onClick={(e) => {
                if (autoClose) {
                    e.stopPropagation()
                }
            }}>
            {prepend}
            <div className="overflow-y-auto flex-grow">{children}</div>
            {append}
        </div>
    )
}

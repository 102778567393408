import { ChangeEvent, useState } from 'react'
import { DeleteImage, UploadImage } from '~/api'
import { COLORS } from '~/constants/colors'
import $sentry from '~/lib/sentry'

import NextImage from 'next/image'
import Icon from '~/components/Icon'
import { IconLoaderCircle } from '~/components/Icon/IconLoaderCircle'
import { useGlobal } from '../Common/GlobalDataProvider'

interface ImageUploaderI {
    className?: string
    image?: string
    setImage?: (value: string) => void
}

export const ImageUploader = ({ className, image, setImage = () => false }: ImageUploaderI) => {
    const { toastProps, setToastProps } = useGlobal()
    const [uploading, setUploading] = useState(false)
    const [removing, setRemoving] = useState(false)
    const [preview, setPreview] = useState(image || '')

    const inputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (uploading) {
            return
        }

        if (!e.target.files) {
            return
        }

        const formData = new FormData()

        const imageList = Array.from(e.target.files)

        let limitExceeded = false

        imageList.forEach((item) => {
            formData.append('file', item)

            // image size more than 5mb
            if (item.size > 5000000) {
                limitExceeded = true
            }
        })

        if (limitExceeded) {
            setToastProps({
                ...toastProps,
                open: true,
                type: 'error',
                message: 'Ukuran gambar tidak boleh melebihi 5MB!'
            })
            return
        }

        setUploading(true)

        const { data } = await UploadImage(formData)

        setUploading(false)

        if (!data) {
            setToastProps({
                ...toastProps,
                open: true,
                type: 'error',
                message: 'Gagal mengunggah gambar barang, silahkan coba lagi!'
            })
            return
        }

        setToastProps({
            ...toastProps,
            open: true,
            type: 'success',
            message: 'Gambar berhasil di upload!'
        })

        setImage(data.fileUrl)
        setPreview(URL.createObjectURL(e.target.files[0]))
    }

    const remove = async () => {
        if (removing) {
            return
        }

        if (image === '' || !image) {
            return
        }

        setRemoving(true)

        const { data } = await DeleteImage(image)

        setRemoving(false)

        if (!data) {
            // setToastProps({
            //     ...toastProps,
            //     open: true,
            //     type: 'error',
            //     message: 'Gagal menghapus gambar, silahkan coba lagi!'
            // })
            // return
        }

        setToastProps({
            ...toastProps,
            open: true,
            type: 'success',
            message: 'Gambar berhasil dihapus!'
        })
        setImage('')
        setPreview('')
    }

    return (
        <div className={className}>
            <div className="w-20 h-20 relative justify-center items-center">
                {image === '' || !image || image === 'null' ? (
                    <>
                        <NextImage
                            src="/images/add-image.svg"
                            width={80}
                            height={80}
                            alt="default"
                            unoptimized></NextImage>
                        <input
                            type="file"
                            accept="image/*"
                            multiple={false}
                            className="w-full h-full absolute left-0 top-0 cursor-pointer opacity-0"
                            onChange={inputChange}></input>
                        {uploading && (
                            <div className="absolute w-full h-full left-0 top-0 bg-opacity-80 rounded-lg bg-neutrals-900 flex justify-center items-center">
                                <IconLoaderCircle className="w-10 h-10"></IconLoaderCircle>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className="flex-wrap relative w-20 h-20">
                            <div className="rounded-md flex items-center justify-center bg-primary-50 w-20 h-20 overflow-hidden">
                                <NextImage
                                    src={preview}
                                    key={image}
                                    width="80"
                                    height="80"
                                    unoptimized
                                    objectFit="cover"
                                    alt="image"
                                    onError={() => {
                                        $sentry.sendSentryEvent('Error loading image: ' + image)
                                    }}></NextImage>
                            </div>
                            <span className="absolute -top-1 -right-1 bg-neutrals-light h-5 w-5">
                                {removing ? (
                                    <IconLoaderCircle className="w-5 h-5"></IconLoaderCircle>
                                ) : (
                                    <Icon
                                        name="trash-outline"
                                        size="lg"
                                        onClick={() => remove()}
                                        color={COLORS.SEMANTIC.RED[500]}></Icon>
                                )}
                            </span>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
